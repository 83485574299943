<template>
  <div class="cahandl mywidth">
    <div class="box-bg">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="pagetop">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>CA办理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tit">河北CA</div>
      <el-table
          :data="hbData"
          style="width: 100%">
        <el-table-column
            prop="name"
            label="工具名称"
            width="40%"
        >
        </el-table-column>
        <el-table-column
            prop="explain"
            label="说明"
            width="40%"
        >
        </el-table-column>
        <el-table-column
            prop="address"
            label="下载地址"
            width="20%"
        >
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.url)" type="primary" icon="el-icon-download" size="small">点击下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="qbl" @click="handleClick('https://hebcaonline.hebca.com/Hebca/certbussconduct/entry.action?onlineType=4&projectType=yzyc&cztype=1','blank')">去办理<i class="el-icon-right"></i></div>
      <div class="xian"></div>
      <div class="tit">北京CA</div>
      <el-table :data="bjData" border style="width: 100%">
        <el-table-column
            prop="name"
            label="工具名称"
            width="40%"
        >
        </el-table-column>
        <el-table-column
            prop="explain"
            label="说明"
            width="40%"
        >
        </el-table-column>
        <el-table-column
            prop="address"
            label="下载地址"
            width="20%"
        >
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.url)" type="primary" icon="el-icon-download" size="small">点击下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="qbl" @click="handleClick('https://www.bjca.cn','blank')">去办理<i class="el-icon-right"></i></div>
    </div>
  </div>

</template>

<script>

export default {
  name: "Cahandl",
  data() {
    return {
      hbData: [{
        name: '河北CA 证书助手',
        explain: '河北CA用户需要下载此版本',
        url: 'https://helper.pasiatec.com/client/HEBCA-helper-ztb.exe'
      }, {
        name: '易招云采投标制作工具(河北版)',
        explain: '河北CA用户需要下载此版本',
        url: 'http://down.ezyunc.com/tool/tender.rar'
      }, {
        name: '易招云采招标制作工具(河北版)',
        explain: '河北CA用户需要下载此版本',
        url: 'http://down.ezyunc.com/tool/bid.rar'
      }, {
        name: '河北腾翔电子签章客户端',
        explain: '河北CA用户需要下载此版本',
        url: 'http://down.ezyunc.com/tool/河北腾翔电子签章客户端.rar'
      }],
      bjData: [{
        name: '北京CA 证书助手',
        explain: '北京CA用户需要下载此版本',
        url: 'http://down.ezyunc.com/tool/BJCA证书助手.exe'
      }, {
        name: '易招云采投标制作工具(北京版)',
        explain: '北京CA用户需要下载此版本',
        url: ''
      }, {
        name: '易招云采招标制作工具(北京版)',
        explain: '北京CA用户需要下载此版本',
        url: ''
      }, {
        name: '北京CA PDF电子签章客户端',
        explain: '北京CA用户需要下载此版本',
        url: 'http://down.ezyunc.com/tool/北京PDF电子签章客户端.exe'
      }]
    };
  },

  methods: {
    handleClick(url,blank) {
      if(url){
        if(blank=='blank'){
          window.open(url,'_blank')
        }else{
           window.location.href = url
        }
      }else {
        this.$alert('技术正在努力开发中,请等待', '温馨提醒', {
          confirmButtonText: '确定',

        });
      }

    }
  },
};
</script>

<style lang="less" scoped>
.pagetop {
  border-bottom: 1px solid @border-color;
  padding: 0 0 10px;
  margin: 10px 0;
}

.cahandl {
  margin: 20px auto;

  .tit {
    font-size: 24px;
    line-height: 40px;
    color: #333333;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  ::v-deep .el-table th.el-table__cell {
    background: #f2f2f2;
    border-right: 1px solid #fff;
    color: #656565;
  }
  .qbl{
    line-height: 40px;
    border-radius: 20px;
    border: 1px solid #419efd;
    text-align: center;
    color: #419efd;
    font-weight: bold;
    width: 50%;
    margin: 30px auto;
    cursor: pointer;
    i{
      font-size: 20px;
      font-weight: bold;
      margin-left: 5px;
    }
    &:hover{
      background: #419efd;
      color: #ffffff;
    }
  }
  .xian{
    background: #f2f2f2;
    height: 5px;
    width: 100%;
    display: block;
    padding: 0 25px;
    margin: 0 -25px;
  }
}

</style>
